import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import { config } from "../config";

class SatisfactionFormService {
  getInstallations(userId, year) {
    return axios
      .get(
        `${config["apiUrl"]}satisfaction-form/installations/user/${userId}/year/${year}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getInstallationForm(installationId) {
    return axios
      .get(`${config["apiUrl"]}satisfaction-form/installation/${installationId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  addInstallationAnswer(installationId, bodyParms) {
    return axios
      .post(`${config["apiUrl"]}satisfaction-form/installation/${installationId}`, bodyParms,  {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getSupportForm(patId) {
    return axios
      .get(`${config["apiUrl"]}satisfaction-form/support/${patId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  addSupportAnswer(supportId, bodyParms) {
    return axios
      .post(`${config["apiUrl"]}satisfaction-form/support/${supportId}`, bodyParms,  {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getPats(userId, year) {
    return axios
      .get(
        `${config["apiUrl"]}satisfaction-form/support/user/${userId}/year/${year}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getInstallationsToExport(start, end) {
    return axios
      .get(
        `${config["apiUrl"]}satisfaction-form/installations/export/${start}/${end}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getSupportsToExport(start, end) {
    return axios
      .get(
        `${config["apiUrl"]}satisfaction-form/supports/export/${start}/${end}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getResume(year) {
    return axios
      .get(
        `${config["apiUrl"]}satisfaction-form/resume/${year}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

}
export default new SatisfactionFormService();
